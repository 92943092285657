(function($){

    function link_is_external(link_element) {
        // Allow for overrides
        if (link_element.host === 'www.netteller.com'){
            return false;
        } else if (link_element.href && link_element.href.indexOf('mailto:') === 0) {
            return false;
        } else if (link_element.href && link_element.href.indexOf('tel:') === 0) {
            return false;
        }

        return (link_element.host !== window.location.host);
    }

    function open_modal(){
        $('#exit-notifier-modal').modal('show');
    }

    function close_modal(){
        $('#exit-notifier-modal').modal('hide');
    }

    $(function(){

        $('a').each(function(){
            if(link_is_external(this)){
                $(this).addClass('external-link');
            }
        });

        $('.external-link').bind('click', function(e){
            e.preventDefault();

            $('#exit-notifier-proceed-to-url').attr('href', $(this).attr('href'));
            open_modal();
        });

        $('#exit-notifier-proceed-to-url').bind('click', function(){
            close_modal();
        });

    });
})(jQuery)